.review-content {
  position: absolute;
  top: 30%;
  right: 5%;
  background-color: rgba(255, 255, 255, 0.95);
  padding: 20px;
  border-radius: 10px;
  font-size: 16px;
  height: auto;
  width: 80%;
  max-width: 800px;
  max-height: 300px;
  overflow-y: auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  z-index: 999;
  animation: slideIn 0.3s ease-out;
}

@keyframes slideIn {
  from {
    transform: translateY(-50%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.review-close {
  position: absolute;
  top: 180px;
  right: 20px;
  cursor: pointer;
  font-size: 24px;
  font-weight: bold;
  color: #000;
  background: #FFF;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  z-index: 1000;
  align-items: center;
}

.review-close:hover {
  background-color: #F00;
}

.review-item {
  list-style-type: none;
  border-bottom: 1px solid #ccc;
  padding: 15px 0;
}

.review-header {
  display: flex;
  align-items: center;
}

.avatar {
  border-radius: 50%;
  width: 45px;
  height: 45px;
  margin-right: 15px;
}

.author-info h4 {
  margin: 0;
  font-size: 24px;
  font-size: 1.2em;
}

.author-info p {
  margin: 5px 0 0;
  font-size: 12px;
  color: #777;
}

.review-item p {
  margin: 10px 0;
}

.review-item a {
  color: #0066cc;
  text-decoration: none;
}

.review-item a:hover {
  text-decoration: underline;
}
