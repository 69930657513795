.chatbox {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%); /* Centers the chatbox horizontally */
    width: 50vw;
    height: 400px;
    background-image: url('https://raw.githubusercontent.com/JustinHennis1/image_assets/main/movieBack.jpeg');
    background-size: cover; 
    background-position: center; 
    background-repeat: no-repeat;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 998;
    transition: height 0.3s ease-in-out;
    overflow: hidden;
  }
  .overlay {
    background: rgb(0, 0, 0, 0.7);
  }
  
  .chatbox.collapsed {
    height: 40px;
    border:2px solid black;
  }
  #chatTitle {

    padding-left: 20px;
  }
  
  .chatbox-header {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    background-color: #444;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    color: white;
    cursor: pointer;
  }
  
  .chatbox-body {
    height: calc(100% - 110px);
    padding: 10px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  }
  
  .message {
    max-width: 60%;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  
  .user-message {
    align-self: flex-end;
    font-weight: bold;
    background-color: #555;
    color: #FFF;
  }
  
  .assistant-message {
    align-self: flex-start;
    font-weight: bold;
    background-color: #ffffffe6;
    color: #171717;
  }
  
  .chatbox-input {
    display: flex;
    width: 90%;
    border: none;
    align-items: center;
    z-index: 2;
  }
  
  .chatbox-input-field {
    margin-left: 10px;
    color: white;
    display: block;
    width: 35vw;
    height: 40px;
    border: none;
    border-radius: 80px;
    padding: 15px;
    box-sizing: border-box;
    background-color: #444;
    font-size: 16px;
    z-index: 2;
  }
  
  
  
  .submitDiv {
    position: absolute;
    display: flex;
    bottom: 10px;
    height: 30px;
    width: auto;
    right: 5%;
    background-color: none;
    z-index: 600;
  }
  
  .submit-button {
    border: 2px groove #222;
    border-radius: 50%;
  }
  
  .submit-button:hover {
    border: 2px groove #FFF;
    border-radius: 50%;
    -webkit-animation: spin 10s linear infinite; /* Safari */
    animation: spin 10s linear infinite;
  }
  
  /*Loading Indicator*/
  .loader {
    border: 4px solid #111;
    border-radius: 50%;
    padding: 0;
    display: block;
    border-top: 4px solid #444;
    width: 30px;
    height: 30px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 1s linear infinite;
  }
  
  .loader2 {
    border: 2px solid #111;
    border-radius: 50%;
    padding: 0;
    display: block;
    border-top: 2px solid #444;
    width: 15px;
    height: 15px;
    -webkit-animation: spin 1s linear infinite; /* Safari */
    animation: spin 1s linear infinite;
  }
  
  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  @media (width < 890px) {
    .submitDiv {
      bottom: 45px;
    }

    .chatbox-input {
      width: 100%;
    }

    .chatbox-input-field {
      width: 45vw;
    }
    
  }
  
  @media (width < 582px) and (width > 320px) {
    .chatbox-body {
      height: calc(100% - 130px);
      padding: 10px;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
    }
  }
  @media (width < 320px) {
    .chatbox-body {
      height: calc(100%-140px);
      padding: 0px;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
    }
    .chatbox{
      z-index: 600;
      width: 100vw;
      height: 200px;
    }
    .chatbox-header{
      overflow: hidden;
      font-size: 10px;;
      height:20px;
    }
  }
  
  .quoted-title {
    font-weight: bold;
    font-size: 18px;
    color: rgb(226, 19, 19); 
    padding: 2px 4px;
    text-decoration: underline;
    background-color: black;
    border-radius: 20px;
   
   
  }
  
  .chatbox-body::-webkit-scrollbar {
    width: 12px;  /* Width of the scrollbar */
  }
  
  .chatbox-body::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.8);  /* Track color */
    border-radius: 10px;  /* Rounded corners for the track */
  }
  
  .chatbox-body::-webkit-scrollbar-thumb {
    background: #555;  /* Thumb color */
    border-radius: 10px;  /* Rounded corners for the thumb */
    border: 3px solid rgba(0, 0, 0, 0.8);  /* Border around the thumb */
  }
  
  .chatbox-body::-webkit-scrollbar-thumb:hover {
    background: #FFF;  /* Thumb color on hover */
  }
  
  .ml-2 {
    margin-left: 0;
  }