/* CreateAccountCard.module.css */

.title{
  text-align: center;
  font-size: 24px;
  font-family: 'Lora';
}

.createAccountContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background: linear-gradient(to top, #f11111, #ffb71b);
}

.createAccountCard {
  background: #fff;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.9);
  width: 100%;
  max-width: 400px;
  text-align: center;
}

.createAccountCard h2 {
  margin-bottom: 20px;
  color: #333;
}

.inputGroup {
  margin-bottom: 15px;
  text-align: left;
}

.inputGroup label {
  display: block;
  margin-bottom: 5px;
  color: #333;
}

.inputGroup input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-sizing: border-box;
}

.createAccountButton {
  width: 100%;
  padding: 10px;
  background-color: #f12711;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.createAccountButton:hover {
  background-color: #ffb71b;
}

.link {
  color: #ffb71b;
}

.errorMessage {
  color: #F00;
}

.resetPassButton {
  width: 100%;
  padding: 10px;
  background-color: #000000;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.resetPassButton:hover {
  width: 50%;
  border-radius: 70px;
  transition: all 2s;
}